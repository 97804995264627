<template>
  <div class="page_order_info right_connent">
    <div class="title">兑换码</div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      :inline="true"
      class="demo-ruleForm"
    >
      <el-form-item class="item_input" label="兑换码" prop="mm_code">
        <el-input
          style="width: 651px"
          v-model="ruleForm.mm_code"
          placeholder="请输入16位密码（卡密码不区分大小写）"
          autocomplete="off"
          clearable
          maxlength="16"
        ></el-input>
      </el-form-item>

      <el-form-item class="item_input" label="验证码" prop="identifyCode">
        <el-input
          style="width: 554px"
          v-model="ruleForm.identifyCode"
          placeholder="请输入验证码"
          autocomplete="off"
          clearable
        ></el-input>
        <div class="get_code" @click="refreshCode()">
          <sIdentify :identifyCode="identifyCode" />
        </div>
      </el-form-item>

      <div class="btn" @click="submitForm('ruleForm')">立即兑换</div>
    </el-form>
  </div>
</template>

<script>
import sIdentify from "@/components/sIdentify.vue";
import api from "@/config/api";
export default {
  //   name: "my_order",
  data() {
    var mm_codeReg = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入兑换码"));
      } else {
        const reg = /^[a-zA-Z\d]{6,16}$/;
        if (reg.test(value)) {
          return callback();
        } else {
          return callback(new Error("请输入16位密码（卡密码不区分大小写）"));
        }
      }
    };
    var identifyCodeReg = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        if (value == this.identifyCode) {
          console.log(this.identifyCode);
          return callback();
        } else {
          return callback(new Error("验证码错误"));
        }
      }
    };
    return {
      ruleForm: {
        mm_code: "",
        identifyCode: "",
      },
      identifyCode: "",
      identifyCodes: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "a",
        "b",
        "c",
        "d",
      ], //根据实际需求加入自己想要的字符
      rules: {
        mm_code: [{ validator: mm_codeReg, trigger: "blur" }],
        identifyCode: [{ validator: identifyCodeReg, trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.refreshCode();
  },
  methods: {
    // 生成随机数
    randomNum(min, max) {
      max = max + 1;
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 更新验证码
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
      // console.log("当前验证码:", this.identifyCode);
    },
    // 随机生成验证码字符串
    makeCode(data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length - 1)];
      }
    },
    submitForm(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.btnmy_coupon();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 提交
    async btnmy_coupon(data) {
      console.log(this.ruleForm.mm_code);
      try {
        const res = await api.exchange({
          code: this.ruleForm.mm_code,
        });
        if (res.code == 0) {
          this.$notify({
            title: "提示信息",
            message: "兑换成功",
            type: "success",
          });
          setTimeout(()=>{
            this.$router.push('/mine/my_course')
          },2000)
        } else {
          this.$notify({
            title: "提示信息",
            message: res.message,
            type: "error",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  components: {
    sIdentify,
  },
};
</script>
<style scoped>
</style>

<style lang="less" scoped>
.right_connent {
  padding: 40px 40px;
  background: #ffffff;
  border-radius: 16px;
  width: 820px;
  min-height: 674px;
  .nav_name {
    font-size: 20px;
    
    font-weight: bold;
    color: #1f1f1f;
    line-height: 28px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e9e9e9;
  }
}
.page_order_info {
  text-align: left;
  .title {
    padding-bottom: 30px;
    font-size: 20px;
    
    font-weight: bold;
    color: #1f1f1f;
    border-bottom: 1px solid #e9e9e9;
  }
  .item_input {
    margin-top: 30px;
    display: flex;
    align-items: center;
    label {
      margin-right: 32px;
      font-size: 14px;
      
      font-weight: 500;
      color: #333333;
      line-height: 28px;
    }
    .get_code {
      width: 88px;
      height: 39px;
      background-color: #479dff;
      margin-left: 10px;
      display: inline-block;
      margin-top: 10px;
      position: absolute;
      right: -139px;
      top: -26%;
      transform: translateX(-50%);
      cursor: pointer;
    }
  }
  .btn {
    width: 161px;
    height: 40px;
    background: #479dff;
    border-radius: 20px;
    line-height: 40px;
    font-size: 18px;
    
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    margin: 0 auto;
    margin-top: 80px;
    cursor: pointer;
  }
}
</style>